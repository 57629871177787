import React, { Fragment } from 'react'
import { Link } from '../ui/misc'
import { H3 } from '../ui/tags'

export const Downloads = () => (
  <Fragment>
    <H3 mb0>Fliers</H3>
    <Link
      block
      to="https://drive.google.com/open?id=1a-J8KVqgryOhCHavv_dmrfGeQtVOI8mz"
    >
      Full Page - Black &amp; White
    </Link>
    <Link
      block
      to="https://drive.google.com/open?id=190V-mfHuRHs-Brl3Il_KOZXDU99Dfuk5"
    >
      Full Page - Color
    </Link>
    <Link
      block
      to="https://drive.google.com/open?id=1BjZdWGWr4DCcMxccVF0ZcjK0ZFhH3Urr"
    >
      Quarter Page - Black &amp; White
    </Link>
    <Link
      block
      to="https://drive.google.com/open?id=1IylIOFe-m1Nf6KvdSRqS4_wq6ixOQw5u"
    >
      Quarter Page - Color
    </Link>
    <H3 mb0 mt1>
      Other
    </H3>
    <Link
      block
      to={'https://drive.google.com/open?id=1OcaLq6VR2n2EcOtc1fRwIe74SIBzx-dC'}
    >
      Speaker Sign Up Form
    </Link>
  </Fragment>
)
