import React from 'react'
import { DownloadsModal } from '../components/DownloadsModal'
import { NeedHelp } from '../components/NeedHelp'
import { Root } from '../components/Root'
import { emails } from '../emails'
import { Button } from '../ui/buttons'
import { Card } from '../ui/cards'
import { Link } from '../ui/misc'
import { DIV, H2, H3, P } from '../ui/tags'
import { url } from '../urls'

const GetInvolved = () => (
  <Root>
    <NeedHelp mb2 />

    <H2>Get Involved</H2>
    <Card>
      <p>There are a few primary ways you can get involved:</p>
      <H3 mb0>Attend the monthly H&amp;I business meeting</H3>
      <P mt0>
        These meetings are held at 6:30pm on the last Wednesday of the month at
        Good Samaritan Hospital in NW Portland. All are welcome and service
        positions are available! We meet in the basement of the{' '}
        <Link to="https://www.google.com/maps/place/Good+Samaritan+Sam+Wilcox+Buildling/@45.5308452,-122.6977524,19z/data=!3m1!4b1!4m5!3m4!1s0x549509faa5b588f5:0xe05f7a41d4b2169c!8m2!3d45.5308443!4d-122.6972052">
          <i className="mr1">place</i>
          Wilcox Building
        </Link>
        . Enter through the door at the back of the parking lot.
      </P>
      <H3 mb0 mt2>
        Speak at an AA meeting at a treatment center.
      </H3>
      <P mt0>
        Let us know when you&apos;re available during the week and we&apos;ll
        reach out when we need speakers. Or take a look at our current
        commitments and let us know if there is a particular one that interests
        you.
      </P>

      <DIV>
        <Button mr1 mt1 to={emails.commitmentAvailability}>
          My Availability
        </Button>
        <Button mt1 to={url.commitments}>
          Pick a Commitment
        </Button>
      </DIV>
      <H3 mb0 mt2>
        Help Spread the Word
      </H3>
      <P mt0>
        Put fliers on your home group&apos;s literature table, pass them out at
        events, and circulate sign up forms to help give more people a chance to
        speak at commitments.
      </P>
      <DownloadsModal
        render={p => (
          <Button {...p}>Download Fliers &amp; Sign Up Forms</Button>
        )}
      />
    </Card>
  </Root>
)

export default GetInvolved
