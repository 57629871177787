import React, { Fragment } from 'react'
import { Downloads } from './Downloads'
import { Modal, ModalManager } from './Modal'

export const DownloadsModal = ({ render }) => (
  <ModalManager
    render={modalProps => (
      <Fragment>
        {render({ onClick: modalProps.open })}
        <Modal title="Downloads" {...modalProps}>
          <Downloads />
        </Modal>
      </Fragment>
    )}
  />
)
